/* eslint-disable @angular-eslint/no-host-metadata-property */
import { AfterViewChecked, Component, OnInit, ViewChild } from '@angular/core';
import { Project } from '../../../_models/project';
import { ProjectService } from '../../../_services/project.service';
import { RoutingHelperService } from '../../../_services/routing-helper.service';
import { Validators, FormBuilder } from '@angular/forms';
import { ApplicationError } from 'src/app/_common/application-error';
import { CommonUtilities } from 'src/app/_common/common-utilities';
import { ApplicationConstants } from 'src/app/_common/application-constants';
import { SlideDataListComponent } from '../../slide-data/slide-data-list/slide-data-list.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { VideoPlayerDialogInfo } from '../../video-player-dialog/VideoPlayerDialogInfo';
import { VideoPlayerDialogComponent } from '../../video-player-dialog/video-player-dialog.component';
import { JobQueueService } from 'src/app/_services/job-queue.service';
import { SecurityService } from 'src/app/_services/security.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CDK_ROW_TEMPLATE } from '@angular/cdk/table';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let bootstrap: any;

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css'],
  host: {
    '(document:keydown.control.1)': 'forceUpdatePresentationVideo()'
  }
})
export class ProjectDetailsComponent implements OnInit, AfterViewChecked {


  public theModel: Project;
  public message: string = ApplicationConstants.emptyMessage;
  public theId: string = ApplicationConstants.emptyMessage;
  public ownerId: string = ApplicationConstants.emptyMessage;
  public isLoadingVideo = false;
  public isLoadingProject = false;
  theForm = this.fb.nonNullable.group({
    projectOwnerId: [ApplicationConstants.defaultString,
    [Validators.minLength(0), Validators.maxLength(100)]],
    projectName: [ApplicationConstants.defaultString,
    [Validators.minLength(0), Validators.maxLength(100)]],
    projectDescription: [ApplicationConstants.defaultString, Validators.required],
    projectId: [ApplicationConstants.defaultString],
    projectEtag: [ApplicationConstants.defaultString],
    projectTimestamp: [ApplicationConstants.defaultDate]
  });

  @ViewChild('slideDataListControl', { static: true })
  private slideDataListControl: SlideDataListComponent | null = null;

  constructor(private projectService: ProjectService,
    private routingHelper: RoutingHelperService,
    private fb: FormBuilder,
    private queueService: JobQueueService,
    public securityService: SecurityService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar) {
    this.theModel = new Project();
  }

  public showToastMessage(message: string, action: string) {
    this.snackBar.open(message, action, { duration: 3000, verticalPosition: 'top', horizontalPosition: 'center' });
  }

  ngOnInit() {
    this.theId = this.routingHelper.getId();
    this.ownerId = this.routingHelper.getValue('ownerId');

    this.initializeTooltips();

    if (this.theId === '0') {
      this.initializeToBlank();
    } else {
      this.loadBy(this.ownerId, this.theId);
    }
  }

  ngAfterViewChecked(): void {
    // console.log('ngAfterViewChecked()');
    this.initializeTooltips();
  }

  initializeTooltips() {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');

    // console.log('tooltipTriggerList: ' + tooltipTriggerList);

    const tooltipList = Array.from(tooltipTriggerList).map(tooltipTriggerEl => {
      // console.log('tooltipTriggerEl: ' + tooltipTriggerEl);

      const tooltip = bootstrap.Tooltip.getOrCreateInstance(tooltipTriggerEl);

      return tooltip;
    });

    // console.debug('tooltipList #: ' + tooltipList.length);

  }


  hideAllTooltips() {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');

    // console.log('tooltipTriggerList: ' + tooltipTriggerList);

    const tooltipList = Array.from(tooltipTriggerList).map(tooltipTriggerEl => {
      // console.log('tooltipTriggerEl: ' + tooltipTriggerEl);

      const tooltip = bootstrap.Tooltip.getOrCreateInstance(tooltipTriggerEl);

      console.log('hiding tooltip: ' + tooltip);
      tooltip.hide();

      return tooltip;
    });

    console.log('hide all tooltips -- tooltipList: ' + tooltipList);
  }

  loadBy(ownerId: string, projectId: string) {
    this.ownerId = ownerId;
    this.theId = projectId;

    this.loadByOwnerAndId(this.ownerId, this.theId);

    if (this.slideDataListControl !== null) {
      this.slideDataListControl.loadByProject(this.ownerId, this.theId);
    }
    else {
      console.log('slideDataListControl is null');
    }
  }

  refresh() {
    this.hideAllTooltips();
    this.message = 'Refreshing...';

    this.loadBy(this.ownerId, this.theId);
  }

  queueSlideScreenshots() {
    this.queueService.queueScreenshots(this.ownerId, this.theId).subscribe({
      next: (data) => {
        this.showToastMessage('Screenshots are being queued for processing.', 'OK');
        console.log(`queueSlideScreenshots() for all slides: data=${data}`);
      },
      error: (error: ApplicationError) => {
        this.message = CommonUtilities.formatErrorMessage(error);
      }
    });
  }

  public showVideoDialog() {
    this.hideAllTooltips();

    if (this.theModel === null) {
      this.message = 'Project not loaded. No project data to show.';
      return;
    }
    else {
      this.isLoadingVideo = true;

      this.projectService.getOwnerAndById(this.theModel.ownerId, this.theModel.id).subscribe({
        next: (item: Project | null) => {
          this.isLoadingVideo = false;
          if (item === null) {
            this.message = `Problem getting updated video information. Data was null.`;
          } else {
            this.showVideoDialogUsingRefreshedProject(item);
          }
        },
        error: (error: ApplicationError) => {
          this.isLoadingVideo = false;
          this.message = `Problem getting updated video information: ${error.friendlyMessage}`;
        }
      });      
    }
  }

  private showVideoDialogUsingRefreshedProject(project: Project) {
    const dialogConfig = new MatDialogConfig();

      dialogConfig.maxHeight = '90vh';

      const data = new VideoPlayerDialogInfo();

      console.log(`showVideoDialogUsingRefreshedProject() -- theModel.videoUrl: ${project.videoUrl}`);

      data.videoUrl = project.videoUrl;
      data.title = `Presentation Video for ${project.name}`;

      dialogConfig.data = data;

      this.dialog.afterOpened.subscribe(() => {
        this.hideAllTooltips();
      });

      this.dialog.afterAllClosed.subscribe(() => {
        this.hideAllTooltips();
      });

      this.dialog.open(VideoPlayerDialogComponent, dialogConfig);
  }

  goToProjectEditor() {
    this.hideAllTooltips();
    this.routingHelper.navigateTo(`/project/project-editor/${this.ownerId}/${this.theId}`);
  }

  forceUpdatePresentationVideo() {
    this.hideAllTooltips();
    this.projectService.queueRefreshPresentationVideo(this.ownerId, this.theId, true).subscribe({
      next: () => {
        this.showToastMessage('Presentation video creation has been queued for processing.', 'OK');
      },
      error: (error: ApplicationError) => {
        this.message = CommonUtilities.formatErrorMessage(error);
      }
    });
  }

  updatePresentationVideo() {
    this.hideAllTooltips();
    this.projectService.queueRefreshPresentationVideo(this.ownerId, this.theId, false).subscribe({
      next: () => {
        this.showToastMessage('Presentation video creation has been queued for processing.', 'OK');
      },
      error: (error: ApplicationError) => {
        this.message = CommonUtilities.formatErrorMessage(error);
      }
    });
  }

  afterLoad() {
    if (this.theModel !== null) {
      console.log('afterLoad() -- theModel is not null');


      this.populateFormFromModel();
    } else {
      console.log('afterLoad() -- theModel is null');
    }
  }

  save() {
    this.message = ApplicationConstants.emptyMessage;
    if (this.theForm.valid) {
      this.beforeSave();

      this.projectService.save(this.theModel).subscribe({
        next: (data: Project) => {
          this.message = ApplicationConstants.savedMessage;
          this.theModel = data;
          this.theId = data.id.toString();
          this.afterLoad();
        },
        error: (error: ApplicationError) => {
          this.message = CommonUtilities.formatErrorMessage(error);
        }
      });
    } else {
      const msg = 'Project form is in an invalid state';
      this.message = msg;
      console.info(msg);
    }
  }

  beforeSave() {
    if (this.theModel !== null) {
      console.log('beforeSave() -- theModel is not null');
      this.populateModelFromForm();

    } else {
      console.log('beforeSave() -- theModel is null');
    }
  }

  navigateToList() {
    this.routingHelper.navigateTo('/project/project-list');
  }

  cancel() {
    this.routingHelper.back();
  }

  refreshAllVideos() {
    this.projectService.refreshAllVideos(this.ownerId, this.theId).subscribe({
      next: () => {
        this.showToastMessage('All videos are being refreshed.', 'OK');
        console.log(`refreshAllVideos()`);
      },
      error: (error: ApplicationError) => {
        this.message = CommonUtilities.formatErrorMessage(error);
      }
    });
  }

  delete() {
    if (window.confirm('Are you sure you want to delete this item?')) {
      if (this.theId !== '0') {
        this.projectService.deleteById(this.theModel.ownerId, this.theId)
          .subscribe({
            next: () => {
              this.navigateToList();
            },
            error: error => {
              this.message = CommonUtilities.formatErrorMessage(error);
            }
          });
      } else {
        this.navigateToList();
      }
    }
  }

  private initializeToBlank() {
    const parentId = this.routingHelper.getValue('parentId');

    if (parentId === null) {
      this.message = 'Parent id for Presentation is missing.';
    } else {
      const temp = new Project();
      // temp.presentationId = parseInt(parentId, 10);
      this.theModel = temp;

      this.populateFormFromModel();
    }
  }
  private populateModelFromForm() {
    const toValue = this.theModel;

    if (toValue === null) {
      this.message = 'the model is null';
    }
    else {
      toValue.ownerId = this.theForm.controls.projectOwnerId.value;
      toValue.name = this.theForm.controls.projectName.value;
      toValue.description = this.theForm.controls.projectDescription.value;
      toValue.etag = this.theForm.controls.projectEtag.value;
      toValue.timestamp = this.theForm.controls.projectTimestamp.value;

    }
  }

  private populateFormFromModel() {
    const fromValue = this.theModel;

    if (fromValue === null) {
      this.message = 'the model is null';
    }
    else {
      this.theForm.patchValue({
        projectOwnerId: fromValue.ownerId,
        projectName: fromValue.name,
        // projectPresentationId: fromValue.presentationId,
        projectId: fromValue.id,
        projectDescription: fromValue.description,
        projectEtag: fromValue.etag,
        projectTimestamp: fromValue.timestamp
      });
    }
  }

  private loadById(id: string) {
    this.projectService.getById(Number(id)).subscribe({
      next: (item: Project | null) => {
        if (item === null) {
          this.theModel = new Project();
          this.message = ApplicationConstants.noDataMessage;
        } else {
          this.theModel = item;
          this.afterLoad();
        }
      },
      error: (error: ApplicationError) => {
        this.message = CommonUtilities.formatErrorMessageFromAny(error);
      }
    });
  }

  private loadByOwnerAndId(ownerId: string, id: string) {
    this.isLoadingProject = true;
    this.projectService.getOwnerAndById(ownerId, id).subscribe({
      next: (item: Project | null) => {
        if (item === null) {
          this.isLoadingProject = false;
          this.theModel = new Project();
          this.message = ApplicationConstants.noDataMessage;
        } else {
          this.isLoadingProject = false;
          this.theModel = item;
          this.message = ApplicationConstants.emptyMessage;
          this.afterLoad();
        }
      },
      error: (error: ApplicationError) => {
        this.isLoadingProject = false;
        this.message = CommonUtilities.formatErrorMessageFromAny(error);
      }
    });
  }

}
