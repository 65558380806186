/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

export function getBaseUrl() {

  let returnValue = environment.baseUrl;

  const temp = document.getElementsByTagName('base')[0].href;

  if (temp === undefined || temp === null || temp === '') {
    returnValue = environment.baseUrl;
  }
  else if (temp.startsWith('https://localhost:4200') === true) {
    returnValue = environment.baseUrl;
  }
  else if (temp.startsWith('https://www.slidespeaker.ai') === true) {
    const temp2 = temp.replace('https://www.', 'https://api.');
    returnValue = temp2;
  }
  else if (temp.startsWith('https://slidespeaker.ai') === true)  {
    const temp2 = temp.replace('https://slidespeaker.ai', 'https://api.slidespeaker.ai');
    returnValue = temp2;
  }
  else if (temp.startsWith('https://app.slidespeaker.ai') === true) {
    const temp2 = temp.replace('https://app.slidespeaker.ai', 'https://api.slidespeaker.ai');
    returnValue = temp2;
  }
  else if (temp.startsWith('https://slidespeaker.') === true) {
    const temp2 = temp.replace('https://slidespeaker.', 'https://slidespeakerapi.');
    returnValue = temp2;
  }
  else if (temp.startsWith('https://slidespeaker-test.') === true) {
    const temp2 = temp.replace('https://slidespeaker-test.', 'https://slidespeakerapi-test.');
    returnValue = temp2;
  }
  else if (temp.startsWith('https://slidespeakerapp-test-a2bncaf5b6h7b6d6.eastus2-01.') === true) {
    const temp2 = temp.replace('https://slidespeakerapp-test-a2bncaf5b6h7b6d6.eastus2-01.', 'https://slidespeakerapi-test.');
    returnValue = temp2;
  } 

  console.log(`getBaseUrl(): ${returnValue}`);
  
  return returnValue;
}

const providers = [{ provide: 'BASE_URL', useValue: getBaseUrl(), deps: [] }];

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.error(err));
