import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProjectDetailsComponent } from
  './_components/project/project-details/project-details.component';
import { ProjectListComponent } from
  './_components/project/project-list/project-list.component';

import { SlideDataDetailsComponent } from
  './_components/slide-data/slide-data-details/slide-data-details.component';
import { SlideDataListComponent } from
  './_components/slide-data/slide-data-list/slide-data-list.component';

import { SlideItemListComponent } from
  './_components/slide-item/slide-item-list/slide-item-list.component';

import { JobQueueDetailsComponent } from './_components/job-queue/job-queue-details/job-queue-details.component';
import { ProjectEditorComponent } from './_components/project/project-editor/project-editor.component';
import { VoiceChooserComponent } from './_components/project/voice-chooser/voice-chooser.component';

import { FailedComponent } from './failed/failed.component';
import { BrowserUtils } from '@azure/msal-browser';
// import { MsalGuard } from '@azure/msal-angular';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './auth.guard';
import { RecentProjectJobsComponent } from './_components/project-job/recent-project-jobs/recent-project-jobs.component';
import { SubscriptionStatusComponent } from './_components/subscription-status/subscription-status.component';
import { SubscriptionThanksComponent } from './_components/subscription-thanks/subscription-thanks.component';
import { AdminComponent } from './_components/admin/admin.component';
import { PresentationOutline } from './_models/presentation-outline';
import { PresentationOutlineListComponent } from './_components/presentation-outline/presentation-outline-list/presentation-outline-list.component';
import { PresentationOutlineDetailComponent } from './_components/presentation-outline/presentation-outline-detail/presentation-outline-detail.component';
import { PresentationOutlineCreateComponent } from './_components/presentation-outline/presentation-outline-create/presentation-outline-create.component';




const routes: Routes = [
  {
    path: '', component: ProjectListComponent, pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  // { path: '', component: HomeComponent, pathMatch: 'full' },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  { path: 'home', component: HomeComponent },
  {
    path: 'project/project-list/:ownerId', component: ProjectListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'presentation-outline/list/:ownerId',
    component: PresentationOutlineListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'presentation-outline/detail/:ownerId/:id',
    component: PresentationOutlineDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'presentation-outline/new/:ownerId',
    component: PresentationOutlineCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin', component: AdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'project/project-details/:ownerId/:id', component: ProjectDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'project/project-editor/:ownerId/:id', component: ProjectEditorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'project-job/recent-project-jobs/:ownerId/:id', component: RecentProjectJobsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'project/voice-chooser/:ownerId/:id', component: VoiceChooserComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'subscription-status', component: SubscriptionStatusComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'subscription-thanks', component: SubscriptionThanksComponent,

  },

  {
    path: 'project', component: ProjectListComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'slide-data/slide-data-list/:ownerId/:projectId', component: SlideDataListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'slide-data/slide-data-details/:ownerId/:projectId/:id', component: SlideDataDetailsComponent,
    canActivate: [AuthGuard]
  },
  { path: 'slide-data', component: SlideDataListComponent },

  {
    path: 'slide-item/slide-item-list', component: SlideItemListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'slide-item', component: SlideItemListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'job-queue', component: JobQueueDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login-failed',
    component: FailedComponent
  },
  { path: '**', component: HomeComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    // Don't perform initial navigation in iframes or popups
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
