<nav style="
    --bs-breadcrumb-divider: url(
      &#34;data:image/svg + xml,
      %3Csvgxmlns='http://www.w3.org/2000/svg'width='8'height='8'%3E%3Cpathd='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z'fill='currentColor'/%3E%3C/svg%3E&#34;
    );
  " aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a routerLink="/project/project-list/{{theModel.ownerId}}">Projects</a>
        </li>
        <li class="breadcrumb-item" *ngIf="isSaved === true && theModel">
            <a routerLink="/project/project-details/{{theModel.ownerId}}/{{ theModel.id }}">{{
                theModel.name
                }}</a>
        </li>
    </ol>
</nav>

<div *ngIf="message!==''">
    <div class="alert alert-primary" role="alert">
        {{message}}
    </div>
</div>

<div class="d-flex align-items-center" *ngIf="isUploading === true">
    <strong role="status">Uploading...</strong>
    <div class="spinner-border ms-auto" aria-hidden="true"></div>
</div>

<div class="d-flex align-items-center" *ngIf="isLoading === true">
    <strong role="status">Loading...</strong>
    <div class="spinner-border ms-auto" aria-hidden="true"></div>
</div>

<ng-template [ngIf]="isLoaded === true && isSaved === true">
    <h2>Project Settings</h2>

    <div>
        <p class="lead">
            These are the settings for your project. You can change the voice, add custom pronunciations, and update the
            name of the project.
        </p>
        <p class="lead">
            Remember that custom pronunciation feature! If you find that certain words are getting mispronounced, you
            can
            add a custom pronunciation to help the voice engine get it right.
        </p>
        <p>
            <button class="btn btn-primary btn-lg" (click)="navigateToProject()">Go to Project</button>
        </p>
    </div>

    <hr />

    <div class="card">

        <div class="card-body">
            <h5 class="card-title">
                Selected Voice
            </h5>
            <div class="row pb-3">
                <div class="col-3">Voice Name: </div>
                <div class="col-4">{{this.theForm.controls.selectedVoiceName.value}}</div>
            </div>

            <div class="row pb-3">
                <div class="col-3">Preview Url: </div>
                <div class="col-4" *ngIf="this.isLoaded === true"><audio controls>
                        <source [src]="this.theForm.controls.selectedVoicePreviewUrl.value" type="audio/mpeg">
                        Your browser does not support the audio element.
                    </audio>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-3"><a routerLink="/project/voice-chooser/{{theModel.ownerId}}/{{theModel.id}}">Change
                        Voice</a>
                </div>
            </div>
        </div>
    </div>
    <div class="spacer p-3"></div>
    <div class="card">

        <div class="card-body">
            <h5 class="card-title">
                Custom word pronunciations
            </h5>
            <h6 class="card-subtitle mb-2 text-muted">Certain words are getting mispronounced? Try adding a custom
                pronunciation.</h6>
            <app-pronunciation-grid [control]="theForm.controls.pronunciationGrid"></app-pronunciation-grid>
            <div class="btn-toolbar pt-3">
                <button type="button" class="btn btn-primary" [disabled]="!theForm.valid" (click)="save()">Save</button>
                <button type="button" class="btn btn-secondary mx-2" (click)="cancel()">Cancel</button>
                <button type="button" class="btn btn-secondary mx-2" hidden="true"
                    (click)="showValidationErrors()">errors</button>
            </div>
        </div>
    </div>

    <div class="spacer p-3"></div>
    <div class="card">

        <div class="card-body">
            <h5 class="card-title">
                Name &amp; Description
            </h5>
            <app-textbox-field [parentForm]="theForm" controlName="projectName" displayName="Project Name"
                #projectName></app-textbox-field>

            <div class="form-group" *ngIf="theForm.controls.projectNameMessage.enabled === true">
                <div class="alert alert-danger" role="alert">
                    {{theForm.controls.projectNameMessage.value}}
                </div>
            </div>

            <app-textbox-field [parentForm]="theForm" controlName="projectDescription"
                displayName="Description"></app-textbox-field>

            <div class="btn-toolbar">
                <button type="button" class="btn btn-primary" [disabled]="!theForm.valid" (click)="save()">Save</button>
                <button type="button" class="btn btn-secondary mx-2" (click)="cancel()">Cancel</button>
                <button type="button" class="btn btn-secondary mx-2" hidden="true"
                    (click)="showValidationErrors()">errors</button>
            </div>

            <div class="row p-3">
                <p class="text-muted">
                    Project slide deck file can't be changed after creation. If you need to change the slide deck,
                    create a
                    new project. Looking for this as a feature? Let us know! We're always looking for feedback.
                    Drop a line to <a href="mailto:info&#64;benday.com">info&#64;benday.com</a>.
                </p>
            </div>
        </div>
    </div>

    <div class="spacer p-3"></div>
    <div class="card">

        <div class="card-body">
            <h5 class="card-title">
                Miscellaneous
            </h5>

            <div class="row">
                <div class="col-4">

                    <button (click)="queuePerSlideScreenshots()" class="btn btn-info m-2 command-button"
                        data-bs-toggle="tooltip" data-bs-placement="top" title="Update Per Slide Screenshots"
                        id="update-per-slide-screenshots-button">
                        update per-slide screenshots
                    </button>
                    <button (click)="downloadProjectData()" class="btn btn-info m-2 command-button"
                        data-bs-toggle="tooltip" data-bs-placement="top" title="Download Project Data">
                        download project data
                    </button>
                    <button (click)="navigateToRecentJobs()" class="btn btn-info m-2 command-button"
                        data-bs-toggle="tooltip" data-bs-placement="top" title="View Recent Jobs">
                        view recent project jobs
                    </button>
                </div>
                <div class="col">&nbsp;</div>
                <div class="col justify-content-end">
                    <button (click)="deleteProjectJobs()" class="btn btn-danger m-2 justify-content-end"
                        data-bs-toggle="tooltip" data-bs-placement="top" title="Delete Project Jobs"
                        id="update-per-slide-screenshots-button">
                        DANGER: delete project jobs
                    </button>

                    <button (click)="markAllAsCurrent()" class="btn btn-danger m-2 justify-content-end">
                        MILD DANGER: mark all as current
                    </button>


                </div>
            </div>
        </div>
    </div>

    <div class="spacer p-3"></div>
    <div>
        <button class="btn btn-primary btn-lg" (click)="navigateToProject()">Go to Project</button>
    </div>
</ng-template>


<ng-container *ngIf="isLoaded === true && isSaved === false">
    <form [formGroup]="theForm">
        <app-textbox-field [parentForm]="theForm" controlName="projectName" displayName="Project Name"
            #projectName></app-textbox-field>

        <div class="form-group" *ngIf="theForm.controls.projectNameMessage.enabled === true">
            <div class="alert alert-danger" role="alert">
                {{theForm.controls.projectNameMessage.value}}
            </div>
        </div>

        <app-textbox-field [parentForm]="theForm" controlName="projectDescription"
            displayName="Description"></app-textbox-field>

        <div class="mb-3">
            <label for="formFile" class="form-label">Powerpoint (*.pptx) File</label>
            <input type="file" id="formFile" class="form-control" (change)="onFileSelected($event)">
        </div>

        <div class="btn-toolbar">
            <button type="button" class="btn btn-primary mx-2"
                [disabled]="theForm.valid == false || isFileValidForUpload === false" (click)="save()">Save</button>
            <button type="button" class="btn btn-secondary mx-2" (click)="cancel()">Cancel</button>
            <!-- <button type="button" class="btn btn-danger mx-2" (click)="delete()">Delete</button> -->
            <button type="button" hidden="true" class="btn btn-secondary mx-2"
                (click)="showValidationErrors()">errors</button>
        </div>
    </form>
</ng-container>