import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, tap } from 'rxjs';
import { CommonUtilities } from '../_common/common-utilities';
import { UserData } from '../_models/user-data';
import { ClaimsResponse } from '../_models/claims-response';
import { SecurityStateInfo } from '../security-state-info';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  public securityState: SecurityStateInfo = new SecurityStateInfo();
  public claimsResponse: ClaimsResponse | null = null;
  public currentUser: UserData | null = null;

  public isUser = false;
  public isAdministrator = false;
  public hasSubscription = false;
  public hasCustomVoices = false;
  public isAdminOrHasSubscription = false;
  public isLoaded = false;

  constructor(
    private http: HttpClient) {
      // call ensureCurrentUserIsPopulated() to get the current user and claims every 30 seconds and the first call should be immediately
      // this.ensureCurrentUserIsPopulated(true);
      setInterval(() => {
        this.ensureCurrentUserIsPopulated(false);
      }, 30000);      
  }

  isLoggedIn(): Observable<boolean> {
    const url = `/api/security/isloggedin`;

    console.log('SecurityService.isLoggedIn()')

    return this.http.get<boolean>(url).pipe(
      catchError(err => CommonUtilities.handleHttpError<boolean>(err))
    );
  }

  ensureCurrentUserIsPopulated(ignoreCache: boolean = false) {
    if (ignoreCache === true) {
      console.log('SecurityService.ensureCurrentUserIsPopulated(true)')
      this.getCurrentUser().subscribe();
      this.getClaims().subscribe();
    }
    else if (this.currentUser === null) {
      console.log('SecurityService.ensureCurrentUserIsPopulated(false) currentUser is null')
      this.getCurrentUser().subscribe();
      this.getClaims().subscribe();      
    }
    else {
      console.log('SecurityService.ensureCurrentUserIsPopulated(false) currentUser is not null...skipping');    
      this.getClaims().subscribe();
    }
  }

  getCurrentUser(): Observable<UserData> {
    const url = `/api/security/user`;

    console.log('SecurityService.getCurrentUser()')

    return this.http.get<UserData>(url).pipe(
      tap(data => {
        console.log('getCurrentUser', data)
        this.currentUser = data;
        this.getClaims();
      }),
      catchError(err => CommonUtilities.handleHttpError<UserData>(err))
    );
  }

  getClaims(): Observable<ClaimsResponse> {
    const url = `/api/security/claims`;

    console.log('SecurityService.getClaims()')

    return this.http.get<ClaimsResponse>(url).pipe(
      tap(data => {
        this.claimsResponse = data;
        this.afterClaimsLoad();
      }),
      catchError(err => CommonUtilities.handleHttpError<ClaimsResponse>(err))
    );
  }

  afterClaimsLoad() {
    if (this.claimsResponse === null) {
      this.isLoaded = false;
      this.isUser = false;
      this.isAdministrator = false;
      this.hasSubscription = false;
      this.hasCustomVoices = false;
      return;
    }
    else {
      this.isLoaded = true;

      this.claimsResponse.claims.sort((a, b) => a.type.localeCompare(b.type));

      const roleClaimName = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
      const roleNameForUser = 'PptxUtil.User';
      const roleNameForAdmin = 'PptxUtil.Admin';

      const ClaimValue_Role_CustomVoiceUser = "PptxUtil.CustomVoices";
      const ClaimValue_SubscriptionStatus = "PptxUtil.SubscriptionStatus";

      this.isUser = this.claimsResponse.claims.some(c => c.type === roleClaimName && c.value === roleNameForUser);
      this.isAdministrator = this.claimsResponse.claims.some(c => c.type === roleClaimName && c.value === roleNameForAdmin);
      this.hasSubscription = this.claimsResponse.claims.some(c => c.type === ClaimValue_SubscriptionStatus && c.value === "ACTIVE");
      this.hasCustomVoices = this.claimsResponse.claims.some(c => c.type === roleClaimName && c.value === ClaimValue_Role_CustomVoiceUser);

      this.isAdminOrHasSubscription = this.isAdministrator || this.hasSubscription;
    }

  }
}
