  <nav
    class='navbar navbar-expand-lg navbar-light bg-white mb-3 border-bottom'>
      <a class="navbar-brand" [routerLink]='["/"]' *ngIf="!securityState.activeAccount">
        <img src="assets/slide-speaker-logo-1.svg" class="d-inline-block align-text-center pe-1" width="40"
          alt="slide speaker logo" />&nbsp;Slide Speaker</a>

      <a class="navbar-brand" [routerLink]='["/project"]' *ngIf="securityState.activeAccount">
        <img src="assets/slide-speaker-logo-1.svg" class="d-inline-block align-text-center pe-1" width="40"
          alt="slide speaker logo" />&nbsp;Slide Speaker</a>

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse"
        aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded" (click)="toggle()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse align-self-center" [ngClass]="{ show: isExpanded }">
        <ul class="navbar-nav flex-grow float-right">
          <li class="nav-item" *ngIf="!securityState.activeAccount">
            <a class="nav-link text-dark" (click)="requestLogin()" (keyup)="handleKeyUp($event)" tabindex="0">Login</a>
          </li>

          <li class="nav-item" *ngIf="securityState.activeAccount" [routerLinkActive]="['link-active']"
            [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link text-dark" [routerLink]="['/project']">
              Projects
            </a>
          </li>
          <li class="nav-item" *ngIf="service.currentUser !== null" [routerLinkActive]="['link-active']"
            [routerLinkActiveOptions]="{ exact: true }">         
            <a 
              class="nav-link text-dark" title="Create a presentation using ChatGPT"
              [routerLink]="['/presentation-outline/list', service.currentUser.ownerId]">
              Outlines
            </a>
          </li> 
          <li class="nav-item" *ngIf="service.currentUser" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
            <a *ngIf="service.hasSubscription === true" class="nav-link text-dark" [routerLink]="['/subscription-status']">
              View Subscription
            </a>
            <a *ngIf="service.hasSubscription === false" class="nav-link text-dark" [routerLink]="['/subscription-status']">
              Subscribe
            </a>
          </li>
          <li class="nav-item nav-link" *ngIf="securityState.activeAccount !== null">
            Hi, {{ securityState.activeAccount.name }}
          </li>
          <li class="nav-item" *ngIf="service.currentUser !== null && service.currentUser.isAdministrator === true">            
            <a class="nav-link text-dark" [routerLink]="['/admin']">
              <span class="badge text-bg-secondary">admin mode</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-dark" href="https://www.slidespeaker.ai/demos"><i class="fa-regular fa-circle-question"></i></a>
          </li>
                   
          <li class="nav-item" *ngIf="securityState.activeAccount">
            <a class="nav-link text-dark" (click)="requestLogout()" (keyup)="handleKeyUp($event)"
              tabindex="0">Logout</a>
          </li>
          <li class="dropdown nav-item" *ngIf="showMenu === true">
            <a data-target="navbarDropdownMenu" class="nav-link dropdown-toggle" (click)="toggle()"
              (keyup)="handleKeyUp($event)" data-toggle="dropdown" role="button" aria-haspopup="true"
              aria-expanded="false" tabindex="0">
              <i class="fa-solid fa-bars"></i>
            </a>
            <ul *ngIf="service.currentUser !== null && service.currentUser.isAdministrator === true"
              class="dropdown-menu" id="navbarDropdownMenu" [ngClass]="{ show: isExpanded }">

              <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
                <a class="nav-link text-dark" (click)="collapse()" href="/securitysummary">Security Summary</a>
              </li>

              <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
                <a class="nav-link text-dark" [routerLink]='["/job-queue"]'>Queue</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
  </nav>
